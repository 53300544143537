<template>
  <v-select
    v-model="selectedOption"
    @search="onSearch"
    :name="name"
    :options="selectOptions"
    :disabled="disabled"
    @input="v => this.$emit('input', v)"
    :class="state === false && 'state-false'"
  >
  <template #no-options="{ search, searching }">
      <template v-if="searching && search.length < minCharSearch">
        Digite ao menos {{minCharSearch}} caracteres para iniciar a busca
      </template>
      <template v-else-if="searching">
        Nenhum valor encontrado para <em>'{{search}}'</em>
      </template>
      <em style="opacity: 0.5" v-else>Nenhuma opção disponível</em>
    </template>
  </v-select>
</template>
<script>
import GenericApi from '@/services/genericRequest';
import logger from '@/services/logger';

export default {
  props: {
    value: {
      type: Object,
      required: false,
      default: null,
    },
    state: {
      type: Boolean,
      required: false,
      default: null,
    },
    route: {
      type: String,
      required: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    initialLabel: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: 'selectComPesquisa',
    },
    searchFilterKey: {
      type: String,
      default: 'searchBy',
    },
    filters: {
      type: Object,
      default: () => {},
    },
    labelKey: {
      type: String,
      required: true,
    },
    idKey: {
      type: String,
      required: true,
    },
    minCharSearch: {
      type: Number,
      default: 3,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    searchInitial: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedOption: {},
      selectOptions: [],
      newestSearch: null,
      apiResult: null,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        if (!v) {
          this.selectedOption = null;
          return;
        }
        if (v && v[this.labelKey] && !this.selectOptions.length) {
          this.search(v[this.labelKey]);
          return;
        }
        this.selectedOption = this.selectOptions.find(
          (option) => option[this.idKey] === v[this.idKey],
        );
      },
    },
    filters() {
      this.search('');
    },
    selectOptions(selectOptions) {
      if (selectOptions && selectOptions.length && this.value) {
        this.selectedOption = selectOptions.find(
          (option) => option[this.idKey] === this.value[this.idKey],
        );
      }
    },
  },
  async mounted() {
    if (this.initialLabel) {
      if (this.searchInitial) {
        await this.search(this.initialLabel);
        if (!this.selectOptions) return;
        const itemInitial = this.selectOptions.find((i) => i.descricao === this.initialLabel);
        this.selectedOption = itemInitial;
        this.$emit('input', this.selectedOption);
      } else {
        this.selectedOption = {
          label: this.initialLabel,
          value: 0,
        };
      }
    }
    if (this.minCharSearch < 1) {
      this.search('');
    }
  },
  methods: {
    async timeout(milliseconds) {
      return new Promise((resolve) => {
        setTimeout(resolve, milliseconds);
      });
    },
    async onSearch(searchText, loading) {
      if (this.route) {
        loading(true);
        const thisSearch = searchText;
        this.newestSearch = searchText;
        await this.timeout(400); // debbouncer
        if (thisSearch !== this.newestSearch) return;
        if (searchText.length < 3) {
          loading(false);
          return;
        }
        await this.search(searchText);

        loading(false);
      }
    },
    async search(searchText) {
      const filters = {
        ...this.filters,
        [this.searchFilterKey]: searchText,
      };
      try {
        this.apiResult = await GenericApi.getWithoutPagination({ filters }, this.route);

        const options = this.apiResult.map((r) => ({
          ...r,
          label: r[this.labelKey],
          value: r[this.idKey],
        }));

        this.selectOptions = options;
      } catch (e) {
        console.log('erro no GenericSelectSearch, rota:', this.route);
        console.log(e);
        swal({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao buscar seleção, favor contactar suporte',
          buttons: { continue: 'Ok..' },
        });
        logger(
          'FRONT: GenericSelectSearch',
          `Erro ao buscar options pro select, dados: ${
            JSON.stringify({
              rota: this.route,
              idKey: this.idKey,
              labelKey: this.labelKey,
              filters,
            })
          }`,
        );
      }
    },
  },
};
</script>

<style>
.vs__search {
  height: 30px;
  border-radius: 7px !important;
}
.state-false {
  border: solid #dc3545 1px;
  border-radius: 7px;
}
</style>
